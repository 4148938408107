import React from "react";
import WelcomeSection from "@src/components/partials/summer-kindness-series/WelcomeSection";
import SEO from "@src/components/SEO";
import HabbitChooserSection from "@src/components/partials/summer-kindness-series/HabbitChooserSection";

function SummerKindnesSeries() {
  return (
    <>
      <SEO
        title="The Summer Kindness Series"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <WelcomeSection />
      <HabbitChooserSection />
    </>
  );
}

export default SummerKindnesSeries;
